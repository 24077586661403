import React from 'react';

export const routes = [
  {
    path: '/setup',
    component: React.lazy(() => import('./bdf-applications/setups/BlankPage')),
  },
  {
    path: '/menusetup',
    component: React.lazy(() => import('./bdf-applications/setups/MenuSetup')),
  },
  {
    path: '/permsetup',
    component: React.lazy(() =>
      import('./bdf-applications/setups/PermissionSetup'),
    ),
  },
  {
    path: '/bucketsetup',
    component: React.lazy(() => import('./bdf-applications/setups/Buckets')),
  },
  {
    path: '/managebucket',
    component: React.lazy(() =>
      import('./bdf-applications/setups/ManageBuckets'),
    ),
  },
  {
    path: '/dynamicbucket',
    component: React.lazy(() =>
      import('./bdf-applications/setups/DynamicBuckets'),
    ),
  },
  {
    path: '/banner',
    component: React.lazy(() => import('./bdf-applications/setups/Banner')),
  },
  {
    path: '/managehelpcenter',
    component: React.lazy(() => import('./bdf-applications/setups/HelpCenter')),
  },
  {
    path: '/feature-release',
    component: React.lazy(() =>
      import('./bdf-applications/setups/FeatureReleasePage'),
    ),
  },
  {
    path: '/admin-snapshot',
    component: React.lazy(() =>
      import('./bdf-applications/setups/AdminSnapshotPage.js'),
    ),
  },
  {
    path: '/feedback-center',
    component: React.lazy(() => import('./application-center/feedback-center')),
  },
  {
    path: '/forceLogout',
    component: React.lazy(() =>
      import('./bdf-applications/setups/ForceLogoutPage'),
    ),
  },
  {
    path: '/storeGuide',
    component: React.lazy(() =>
      import('./bdf-applications/setups/StoreGuidePage.js'),
    ),
  },
  {
    path: '/merch-email-setup',
    component: React.lazy(() =>
      import('./bdf-applications/setups/MerchEmailSetupPage'),
    ),
  },
  {
    path: '/guest-support',
    component: React.lazy(() =>
      import('./bdf-applications/setups/GuestSupportSetup.js'),
    ),
  },
  {
    path: '/outlet-tags',
    component: React.lazy(() =>
      import('./bdf-applications/setups/OutletTags.js'),
    ),
  },
  {
    path: '/role-permission',
    component: React.lazy(() =>
      import('./bdf-applications/setups/RolePermission.js'),
    ),
  },
  {
    path: '/disposition-question',
    component: React.lazy(() =>
      import('./bdf-applications/setups/DispositionQuestionSetup.js'),
    ),
  },
  {
    path: '/store-holiday-hours',
    component: React.lazy(() =>
      import('./bdf-applications/setups/holiday-store-hours'),
    ),
  },
];

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { RedButton } from '../../components/CommonComponent';

export default function OpportunityRemoveDialog({
  isOpen,
  setRemoveDialogOpen,
  callbackConfirmFunc,
  isEngageFloor,
}) {
  return (
    <>
      {/* Confirmation Dialog Before Remove Opportunity */}
      <Dialog
        open={isOpen}
        onClose={() => setRemoveDialogOpen()}
        aria-labelledby="remove-dialog-confirmation"
        aria-describedby="remove-dialog-confirmation-description"
      >
        <DialogTitle id="remove-dialog-confirmation">
          Remove Opportunity
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-dialog-confirmation-description">
            {isEngageFloor
              ? 'Are you sure you want to close this opportunity and remove the GXS from the queue?'
              : 'Are you sure you want to remove the GXS from the queue?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <RedButton
            label="Cancel"
            outline={true}
            onClick={(e) => {
              setRemoveDialogOpen();
            }}
          />
          <RedButton
            label="Confirm"
            onClick={(e) => {
              setRemoveDialogOpen();
              callbackConfirmFunc();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

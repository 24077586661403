import React, { useEffect, useRef, useCallback, memo } from 'react';
import { withRouter } from 'react-router-dom';

//Contents
import { Header, Sidebar } from './index';

//Utils
import PropTypes from 'prop-types';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';

//Service
import NotificationSystem from 'react-notification-system';

//Icons
import { MdInfo } from 'react-icons/md';

//Styles
import '../styles/fonts/font.css';

/**
 * Breakpoint values for responsive layout
 * @constant {string[]}
 */
const MOBILE_BREAKPOINTS = ['xs', 'sm', 'md'];

/**
 * Main layout component that handles the overall application structure
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render
 * @param {string} props.breakpoint - Current breakpoint value
 * @param {Object} props.history - React Router history object
 */
const MainLayout = ({ children, breakpoint, history }) => {
  // Refs
  const notificationSystemRef = useRef(null);

  /**
   * Check if sidebar is currently open
   * @returns {boolean} True if sidebar is open
   */
  const isSidebarOpen = useCallback(() => {
    const sidebar = document.querySelector('.cr-sidebar');
    return sidebar?.classList.contains('cr-sidebar--open') || false;
  }, []);

  /**
   * Toggle sidebar state based on breakpoint
   * @param {string} breakpoint - Current breakpoint value
   */
  const handleSidebarState = useCallback((breakpoint) => {
    const sidebar = document.querySelector('.cr-sidebar');
    if (!sidebar) return;
  }, []);

  /**
   * Display notification message
   * @param {string} message - Notification message
   * @param {string} [type='success'] - Notification type
   * @param {React.ReactNode} [children] - Additional notification content
   * @param {number} [delay=0] - Delay before showing notification
   */
  const notification = useCallback(
    (message, type = 'success', children = '', delay = 0) => {
      setTimeout(() => {
        if (!notificationSystemRef.current) return;

        notificationSystemRef.current.addNotification({
          title: <MdInfo />,
          message,
          level: type,
          children,
        });
      }, delay);
    },
    [],
  );

  /**
   * Handle navigation to new URL
   * @param {string} url - Target URL
   */
  const redirect = useCallback(
    (url) => {
      history.push(url);
    },
    [history],
  );

  /**
   * Handle content click to close sidebar on mobile
   */
  const handleContentClick = useCallback(() => {
    if (isSidebarOpen() && MOBILE_BREAKPOINTS.includes(breakpoint)) {
      handleSidebarState(breakpoint);
    }
  }, [breakpoint, isSidebarOpen, handleSidebarState]);

  // Initialize global functions and handle breakpoint changes
  useEffect(() => {
    // Expose functions globally
    window.message = { ...window.message, notification };
    window.redirect = redirect;
    window.isExternal = false;

    // Handle initial sidebar state
    handleSidebarState(breakpoint);

    // Cleanup function
    return () => {
      window.message = undefined;
      window.redirect = undefined;
      window.isExternal = undefined;
    };
  }, [breakpoint, notification, redirect, handleSidebarState]);

  return (
    <main className="cr-app">
      <Sidebar />
      <Header />
      <div className="cr-content container" onClick={handleContentClick}>
        {children}
      </div>
      <NotificationSystem
        dismissible={false}
        ref={notificationSystemRef}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
    </main>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(withRouter(MainLayout));

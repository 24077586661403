// Scaling Service to handle application zoom functionality
const helper = require('../utils/helper');
const enums = require('../enums');
const ux = require('../application-center/ux-tracking-center');

class ScalingService {
  static minZoom = 80;
  static maxZoom = 90;

  /**
   * Apply zoom scaling to the entire application
   * @param {number} zoomValue - Zoom percentage (80-90)
   */
  static applyZoom(zoomValue) {
    if (zoomValue >= this.minZoom && zoomValue <= this.maxZoom) {
      const scale = zoomValue / 100;

      // Update CSS variables for scaling
      document.documentElement.style.setProperty('--scale-factor', scale);

      // Apply transform scale
      document.body.style.transform = `scale(${scale})`;
    }
  }

  /**
   * Reset zoom to default (100%)
   */
  static resetZoom() {
    document.documentElement.style.setProperty('--scale-factor', 1);
    document.body.style.transform = 'none';
  }

  /**
   * Get current scale factor
   * @returns {number} Current scale factor
   */
  static getCurrentScale() {
    return (
      parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue(
          '--scale-factor',
        ),
      ) || 1
    );
  }

  /**
   * Update header-height on collapse gauges
   * @param {boolean} isCollapsed - True if header is collapsed, false otherwise
   */
  static updateHeaderHeight(isCollapsed) {
    const root = document.documentElement;
    root.style.setProperty('--header-height', isCollapsed ? '55px' : '130px');
  }

  /**
   * Save zoom preference to server
   * @param {number} zoomValue - Zoom percentage (80-90)
   */
  static async saveZoomPreference(zoomValue) {
    const payload = {
      empId: helper.getEmplId(),
      zoomLevel: zoomValue,
    };

    try {
      const response = await fetch(
        helper.getAPIHost() + '/saveUserPreferences',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          ...helper.apiHeaders(),
        },
      );

      const data = await response.json();
      if (data.status === 'OK') {
        localStorage.setItem('scaleFactor', zoomValue);
        this.applyZoom(zoomValue);
        return true;
      } else {
        window.message.notification(
          'Failed to save zoom preference.',
          enums.notificationType.E,
        );
        ux.logError('ScalingService', 'saveZoomPreference', data.errorMsg, '');
        return false;
      }
    } catch (error) {
      window.message.notification(
        'Failed to save zoom preference.',
        enums.notificationType.E,
      );
      ux.logError('ScalingService', 'saveZoomPreference', error.message, '');
      return false;
    }
  }

  static initialize() {
    const savedZoom = localStorage.getItem('scaleFactor') || '100';
    if (savedZoom) {
      this.applyZoom(parseInt(savedZoom));
    }
  }
}

export default ScalingService;

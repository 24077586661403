import ScalingService from './ScalingService';

/**
 * Service to handle scroll-based header collapse behavior.
 * Uses Singleton pattern to ensure only one instance manages scroll events.
 */
class ScrollService {
  static instance = null;
  static subscribers = new Set();
  static isCollapsed = false;
  static COLLAPSE_THRESHOLD = 50;

  /**
   * Get the singleton instance of ScrollService
   * @returns {ScrollService} The singleton instance
   */
  static getInstance() {
    if (!ScrollService.instance) {
      ScrollService.instance = new ScrollService();
    }
    return ScrollService.instance;
  }

  constructor() {
    // Enforce singleton pattern
    if (ScrollService.instance) {
      return ScrollService.instance;
    }

    this.observer = null;
    this.currentElement = null;
    this.scrollHandler = null;
    ScrollService.instance = this;

    // Initialize core functionality
    this.setupRouteListener();
    this.watchScrollContainer();
  }

  /**
   * Continuously monitors DOM for .cr-app element and manages scroll listener
   * Uses MutationObserver to detect DOM changes and react accordingly
   */
  watchScrollContainer() {
    // Cleanup any existing observer
    if (this.observer) {
      this.observer.disconnect();
    }

    // Configure and create new observer
    const config = { childList: true, subtree: true };
    const targetNode = document.querySelector('#root') || document.body;

    this.observer = new MutationObserver(() => {
      const crApp = document.querySelector('.cr-app');
      if (crApp && (!this.currentElement || this.currentElement !== crApp)) {
        this.setupScrollListener(crApp);
      }
    });

    // Start observing DOM changes
    this.observer.observe(targetNode, config);

    // Initial check for .cr-app
    const crApp = document.querySelector('.cr-app');
    if (crApp) {
      this.setupScrollListener(crApp);
    }
  }

  /**
   * Sets up route change listeners for SPA navigation
   * Handles both pushState and popstate events
   */
  setupRouteListener() {
    // Intercept pushState calls
    const pushState = window.history.pushState;
    window.history.pushState = (...args) => {
      pushState.apply(window.history, args);
      this.handleRouteChange();
    };

    // Handle browser back/forward
    window.addEventListener('popstate', () => this.handleRouteChange());
  }

  /**
   * Handles cleanup and reinitializes scroll watching on route changes
   */
  handleRouteChange() {
    this.cleanup();
    ScrollService.isCollapsed = false;
    this.notifySubscribers();
    this.watchScrollContainer();
  }

  /**
   * Sets up scroll event listener for the content element
   * @param {HTMLElement} crApp - The content container element
   */
  setupScrollListener(crApp) {
    this.cleanup();
    this.currentElement = crApp;

    // Create scroll handler with passive event for better performance
    this.scrollHandler = (event) => {
      const scrollTop = event.target.scrollTop;
      const shouldCollapse = scrollTop >= ScrollService.COLLAPSE_THRESHOLD;

      if (shouldCollapse !== ScrollService.isCollapsed) {
        ScrollService.isCollapsed = shouldCollapse;
        // Scaling Service : Update header height dynamically
        ScalingService.updateHeaderHeight(shouldCollapse);
        this.notifySubscribers();
      }
    };

    crApp.addEventListener('scroll', this.scrollHandler, { passive: true });

    // Set initial state
    this.scrollHandler({ target: crApp });
  }

  /**
   * Cleans up event listeners and references
   */
  cleanup() {
    if (this.currentElement && this.scrollHandler) {
      this.currentElement.removeEventListener('scroll', this.scrollHandler);
      this.currentElement = null;
      this.scrollHandler = null;
    }
  }

  /**
   * Subscribes to scroll state changes
   * @param {Function} callback - Function to call when scroll state changes
   * @returns {Function} Unsubscribe function
   */
  subscribe(callback) {
    ScrollService.subscribers.add(callback);
    callback(ScrollService.isCollapsed);

    return () => {
      ScrollService.subscribers.delete(callback);
    };
  }

  /**
   * Notifies all subscribers of state changes
   */
  notifySubscribers() {
    ScrollService.subscribers.forEach((callback) => {
      try {
        callback(ScrollService.isCollapsed);
      } catch (error) {
        console.error('[ScrollService] Error in subscriber callback:', error);
      }
    });
  }
}

export default ScrollService.getInstance();

import React, { useState, useEffect } from 'react';
import {
  DeliverSales,
  CoachingLaunchpad,
  Clock,
  Sofa,
  Table,
  Hand,
  House,
  Money,
  Helpicon,
  bobassist,
  BobsNoti,
  ArchivedIcon,
} from '../../assets/img/menus';
import './styles.scss';
import { closeNotification } from './data';
import { RedButton } from '../../components/CommonComponent';
import Grid from '@mui/material/Grid';

export default function LeftContent({
  notification,
  archivedNotification,
  getProperty,
  setShowNotifications,
}) {
  const [notificationList, setNotificationList] = useState([]);
  const [archivedNotificationList, setArchivedNotificationList] = useState([]);
  const [activeButton, setActiveButton] = useState('general');

  //Define getMessageById function
  const getMessageById = (uid, isArchived) => {
    if (isArchived) {
      return archivedNotificationList.find((item) => item.uid === uid);
    } else {
      return notificationList.find((item) => item.uid === uid);
    }
  };

  //Define removeMessageByMsgId function
  const removeMessageByMsgId = (uid) => {
    setNotificationList((prevList) =>
      prevList.filter((item) => item.uid !== uid),
    );
  };

  useEffect(() => {
    const localNotification = notification || [];
    const localArchivedNotification = archivedNotification || [];

    setNotificationList(localNotification);
    setArchivedNotificationList(localArchivedNotification);
  }, [notification, archivedNotification]);

  const pushToArchived = async (val) => {
    try {
      await closeNotification(val, false);
      setNotificationList((prevList) =>
        prevList.filter((item) => item !== val),
      );
      setArchivedNotificationList((prevList) => [...prevList, val]);
      getProperty();
    } catch (error) {
      console.error('Error archiving the message:', error);
    }
  };

  const handleNotificationClick = (type, val, isArchived) => {
    if (type === 'huddleResponse') {
      let curMsg = getMessageById(val.uid, isArchived);
      console.log('huddleResponse - curMsg:', curMsg);
      if (curMsg && curMsg.hasOwnProperty('refURL') && curMsg.refURL) {
        window.redirect(curMsg.refURL);
        setShowNotifications(false);
      }
    } else if (type === 'huddleRevertRequest') {
      let curMsg = getMessageById(val.uid, isArchived);
      console.log('huddleRevertRequest - curMsg:', curMsg);

      if (curMsg && curMsg.hasOwnProperty('refURL') && curMsg.refURL) {
        window.redirect(curMsg.refURL);
        setShowNotifications(false);
      }
    } else if (type === 'deliverCommission') {
      let startDate = val.customField2;
      let endDate = val.customField3;
      console.log('deliverCommission - startDate:', startDate);
      console.log('deliverCommission - endDate:', endDate);

      if (startDate !== null && endDate !== null) {
        window.redirect(
          `/commission?startDate=${startDate}&endDate=${endDate}`,
        );
        setShowNotifications(false);
      }
    } else if (type === 'deliverValue') {
      console.log('deliverValue - val:', val);
      window.redirect('/home?s=bd&selectedTab=2');
      setShowNotifications(false);
    } else if (type === 'OrderEscalation') {
      let curMsg = getMessageById(val.uid, isArchived);
      console.log('OrderEscalation - curMsg:', curMsg);

      if (curMsg && curMsg.hasOwnProperty('refURL') && curMsg.refURL) {
        window.redirect(curMsg.refURL);
        setShowNotifications(false);
      } else if (type === 'FeedbackReply') {
        let curMsg = getMessageById(val.uid, isArchived);
        console.log('FeedbackReply - curMsg:', curMsg);
        if (curMsg && curMsg.hasOwnProperty('refURL') && curMsg.refURL) {
          window.redirect('/feedback?id=' + curMsg.refURL);
          setShowNotifications(false);
        } else if (
          type.toUpperCase().trim() === 'BobAssist'.toUpperCase().trim()
        ) {
          if (val.customField3 === '1' || val.customField3 === '3') {
            window.open(val.customField8, '_blank');
          }
        }
        setShowNotifications(false);
      } else if (
        type.toUpperCase().trim() === 'GoBackRequestFailed'.toUpperCase().trim()
      ) {
        let url =
          'http://sharepoint/Workplaces/DC/Delivery/Lists/Go Back Requests/Todays Go Back.aspx';
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    }
    if (isArchived) {
      removeMessageByMsgId(val.uid);
    }

    console.log(type);
  };

  const checkIcon = (type, msg) => {
    switch (type) {
      case 'scheduleOrder':
        return (
          <img
            alt="Schedule Order"
            className="notiIcon"
            src={DeliverSales.default}
          />
        );
      case 'huddleRevertRequest':
      case 'huddleResponse':
        return (
          <img
            alt="Huddle Revert Request"
            className="notiIcon"
            src={CoachingLaunchpad.default}
          />
        );
      case 'FollowUp':
      case 'CnCFollowUp':
        return <img alt="Follow Up" className="notiIcon" src={Clock.default} />;
      case 'deliverCommission':
        return (
          <img
            alt="Deliver Commission"
            className="notiIcon"
            src={Money.default}
          />
        );
      case 'deliverValue':
        return msg.customField2 === '1' ? (
          <img alt="Sofa" className="notiIconValue" src={Sofa.default} />
        ) : msg.customField2 === '2' ? (
          <img alt="Table" className="notiIconValue" src={Table.default} />
        ) : msg.customField2 === '3' ? (
          <img alt="Hand" className="notiIconValue" src={Hand.default} />
        ) : msg.customField2 === '4' ? (
          <img alt="House" className="notiIconValue" src={House.default} />
        ) : (
          ''
        );
      case 'OrderEscalation':
        return (
          <img
            alt="Order Escalation"
            className="notiIcon"
            src={Helpicon.default}
          />
        );
      case 'FeedbackReply':
        return (
          <img
            alt="Feedback Reply"
            className="notiIconValue"
            src={Hand.default}
          />
        );
      case 'BobAssist':
        return (
          <img alt="Bob Assist" className="notiIcon" src={bobassist.default} />
        );
      case 'deliverCommissionInq':
        return (
          <img
            alt="Deliver Commission Inquiry"
            className="notiIcon"
            src={CoachingLaunchpad.default}
          />
        );
      default:
        return '';
    }
  };

  //disable scroll
  const EmptyNotificationLayout = () => {
    return (
      <div className="empty-notification">
        <p
          style={{
            position: 'relative',
            textAlign: 'center',
            marginTop: '140px',
            zIndex: 2,
          }}
        >
          Oh my Bob, you're caught up on notifications!
        </p>
        <img
          src={BobsNoti.default}
          alt="No Notifications"
          style={{
            position: 'absolute',
            bottom: '2rem',
            left: '1rem',
            maxWidth: 'auto',
            maxHeight: '25vh',
            transition: 'max-height 0.3s ease',
            zIndex: 1,
          }}
        />
      </div>
    );
  };

  const handleButtonChange = (button) => {
    setActiveButton(button);
  };

  return (
    <div className="app-container">
      <Grid
        container
        className={`notifications-container ${
          activeButton === 'general' && notificationList.length === 0
            ? 'nooverflow'
            : ''
        }`}
        spacing={1}
        style={{ height: '100%' }}
      >
        <Grid item xs={12}>
          <div className="notifications-buttons">
            <RedButton
              label="General Notifications"
              outline={activeButton !== 'general'}
              onClick={() => handleButtonChange('general')}
              customStyle="nav-btn"
            />

            <RedButton
              label="Archived Notifications"
              outline={activeButton !== 'archived'}
              onClick={() => handleButtonChange('archived')}
              customStyle="nav-btn"
            />
          </div>
          <div className="notifications-title">
            <label>
              {activeButton === 'general'
                ? 'General Notifications'
                : 'Archived Notifications'}
            </label>
          </div>
          <div className="notifications-body">
            {activeButton === 'general' ? (
              notificationList.length === 0 ? (
                <EmptyNotificationLayout />
              ) : (
                notificationList.map((val, i) => {
                  return (
                    <div className="notifications-detail" key={i}>
                      <div className="notifications-icon">
                        {checkIcon(val.type, val)}
                      </div>
                      <div
                        className="notifications-message"
                        onClick={() =>
                          handleNotificationClick(val.type, val, false)
                        }
                        style={{
                          cursor: 'pointer',
                          ':hover': { backgroundColor: 'lightgray' },
                        }}
                      >
                        <label>{val.message}</label>
                      </div>
                      <div
                        className="notiIcon-container"
                        style={{ width: '20%' }}
                      >
                        <img
                          alt=""
                          className="notiIcon"
                          src={ArchivedIcon.default}
                          onClick={() => pushToArchived(val)}
                        />
                      </div>
                    </div>
                  );
                })
              )
            ) : archivedNotificationList.length === 0 ? (
              <EmptyNotificationLayout />
            ) : (
              archivedNotificationList.map((val, i) => {
                return (
                  <div className="notifications-detail" key={i}>
                    <div className="notifications-message">
                      <label>{val.message}</label>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import React from 'react';
import {
  Table,
  //    Card,
  //    CardBody,
  //    CardTitle,
} from 'reactstrap';
//import SemiCircleProgressBar from "react-progressbar-semicircle";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Circle from 'react-circle';
import './gauges.scss';
import { ClickAwayListener } from '@mui/material';

import scrollService from '../../../services/ScrollService';

const helper = require('../../../utils/helper');
const enums = require('../../../enums');
const initdata = require('../../../application-center/login-center/lib/dataInitialization');
const ux = require('../../../application-center/ux-tracking-center');

class BDFGauge extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  state = {
    isLoading: true,
    data: [],
    fullStyle: true,
    performanceMatrix: {
      [helper.KPIType.ws]: [],
      [helper.KPIType.ds]: [],
      [helper.KPIType.nps]: [],
      [helper.KPIType.ga]: [],
      [helper.KPIType.pa]: [],
      [helper.KPIType.spch]: [],
      [helper.KPIType.twa]: [],
    },
  };

  getData() {
    let dashboardstr = localStorage.getItem('DashboardKPIs');
    let dashboard = {};
    let ws = {};
    let ds = {};
    let ga = {};
    let pa = {};
    let nps = {};
    let wf = {};
    let rkw = '';
    let rkd = '';
    let npsr = '';
    let gar = '';
    let par = '';
    let wpa = '';
    let sph = '';
    let spch = {};

    if (dashboardstr) {
      dashboard = JSON.parse(dashboardstr);
      console.log('dashboard');
      console.log(dashboard);
      ws = dashboard.writtenSalesGauge || {};
      ds = dashboard.deliveredSalesGauge || {};
      ga = dashboard.guardianAttachGauge || {};
      pa = dashboard.protectorAttachGauge || {};
      nps = dashboard.npsGauge || {};
      wf = dashboard.wellsPreApproveGauge || {};
      spch = dashboard.salesPerCallHandledGauge || {};
      //Variable for Ranking
      rkw = dashboard.writtenSalesRanking || '';
      rkd = dashboard.deliveredSalesRanking || '';
      npsr = dashboard.npsRanking || '';
      gar = dashboard.guardianAttachRanking || '';
      par = dashboard.protectorAttachRanking || '';
      wpa = dashboard.wellsPreAppRanking || '';
      sph = dashboard.salesPerCallHandledRanking || '';
    }

    let { userPreferences } = this.props;
    let view = localStorage.getItem('BobSquidManagerView');

    let data = [];

    if (userPreferences.writtenSalesMetric) {
      data.push({
        title: 'Written Sales', //+(Math.random() * 1000).toFixed(0),
        percentage: ws.goal > 0 ? (ws.actual / ws.goal) * 100 : -0,
        value: '$' + helper.formatNumber(ws.actual || 0, 2),
        real: ws.actual,
        inCircle: '', //"$" + helper.shortNumber(ws.totalWrittenSalesActual || 0),
        //inCircle: "$15M",
        splitLine: true,
        // rank: helper.getUserGroup() === enums.userGroups.Showroom ? ws.wsCurrentAssociatesRanking || 0 : "",
        rank:
          helper.getUserGroup() === enums.userGroups.Showroom ||
          helper.getUserGroup() === enums.userGroups.Outlet ||
          helper.getUserGroup() === enums.userGroups.BobsSquad
            ? rkw || ''
            : '',
        goal: helper.formatNumber(ws.goal, 0, '$'),
        actual: helper.formatNumber(ws.actual, 0),
        intpercentage: ws.intelligentRate,
        kpiType: helper.KPIType.ws,
      });
    }

    if (userPreferences.deliveredSalesMetric) {
      data.push({
        title: 'Delivered Sales',
        percentage: ds.goal > 0 ? (ds.actual / ds.goal) * 100 : -0,
        value: '$' + helper.formatNumber(ds.actual || 0, 2),
        real: ds.actual,
        inCircle: '', //"$" + helper.shortNumber(ds.totalActualDeliveredSales || 0),
        splitLine: true,
        // rank: helper.getUserGroup() === enums.userGroups.Showroom ? ds.dsCurrentAssociatesRanking || 0 : "",
        rank:
          helper.getUserGroup() === enums.userGroups.Showroom ||
          helper.getUserGroup() === enums.userGroups.Outlet ||
          helper.getUserGroup() === enums.userGroups.BobsSquad
            ? rkd || ''
            : '',
        goal: helper.formatNumber(ds.goal, 0, '$'),
        actual: helper.formatNumber(ds.actual, 0),
        intpercentage: ds.intelligentRate,
        kpiType: helper.KPIType.ds,
      });
    }

    if (userPreferences.netPromoterScoreMetric) {
      data.push({
        title: 'Net|Promoter Score', // add |, later will replace with space, this is to avoid first 2 words wrap to 2 lines
        percentage: nps.goal > 0 ? (nps.actual / nps.goal) * 100 : -0,
        value: helper.formatNumber(nps.actual || 0, 0) + '',
        real: nps.actual,
        inCircle: '', //helper.shortNumber(nps.totalNPSActual || 0, 1),
        rank:
          helper.getUserGroup() === enums.userGroups.Showroom ||
          helper.getUserGroup() === enums.userGroups.Outlet ||
          helper.getUserGroup() === enums.userGroups.BobsSquad
            ? npsr || ''
            : '',
        goal: helper.formatNumber(nps.goal, 0),
        actual: helper.formatNumber(nps.actual, 0),
        splitLine: true,
        boldFirstLetter: false, //true
        kpiType: helper.KPIType.nps,
      });
    }

    if (userPreferences.protectorAttachMetric) {
      data.push({
        title: 'Protector Attach',
        percentage: pa.goal > 0 ? (pa.actual / pa.goal) * 100 : -0,
        value: helper.formatNumber(pa.actual, 1, '', '%'),
        real: pa.actual,
        inCircle: '',
        rank:
          helper.getUserGroup() === enums.userGroups.Showroom ||
          helper.getUserGroup() === enums.userGroups.Outlet ||
          helper.getUserGroup() === enums.userGroups.BobsSquad
            ? par || ''
            : '',
        goal: helper.formatNumber(pa.goal, 0, '', '%'),
        actual: helper.formatNumber(pa.actual, 0),
        splitLine: true,
        kpiType: helper.KPIType.pa,
      });
    }

    if (
      userPreferences.guardianAttachMetric &&
      helper.getUserGroup() !== enums.userGroups.Outlet
    ) {
      data.push({
        title: 'Guardian Attach',
        percentage: ga.goal > 0 ? (ga.actual / ga.goal) * 100 : -0,
        value: helper.formatNumber(ga.actual, 1, '', '%'), //helper.formatNumber(Math.min(helper.formatNumber(ga.actual || 0, 0), 100),1) + "%",
        real: ga.actual,
        inCircle: '', //helper.shortNumber(ga.totalActualGuardianAttach || 0, 1),
        rank:
          helper.getUserGroup() === enums.userGroups.Showroom ||
          helper.getUserGroup() === enums.userGroups.Outlet ||
          helper.getUserGroup() === enums.userGroups.BobsSquad
            ? gar || ''
            : '',
        goal: helper.formatNumber(ga.goal, 0, '', '%'),
        actual: helper.formatNumber(ga.actual, 0),
        splitLine: true,
        kpiType: helper.KPIType.ga,
      });
    }

    if (
      userPreferences.salesPerCallHandledMetric &&
      (helper.getUserGroup() === enums.userGroups.BobsSquad || view === 'true')
    ) {
      data.push({
        // TODO
        title: 'Sales|Per Call|Handled', // use | to join 2 words
        percentage: spch.goal > 0 ? (spch.actual / spch.goal) * 100 : -0,
        value: '$' + helper.formatNumber(spch.actual || 0, 2),

        real: spch.actual,
        inCircle: '', //wf.totalWellsPreApproveActual || 0,
        rank:
          helper.getUserGroup() === enums.userGroups.Showroom ||
          helper.getUserGroup() === enums.userGroups.Outlet ||
          helper.getUserGroup() === enums.userGroups.BobsSquad
            ? sph || ''
            : '',
        goal: helper.formatNumber(spch.goal, 0),
        actual: helper.formatNumber(spch.actual, 0),
        splitLine: true,
        kpiType: helper.KPIType.spch,
      });
    } else {
      if (userPreferences.totalWellsAppsMetric) {
        data.push({
          title: 'Total Wells' + String.fromCharCode(160) + 'Apps',
          percentage: wf.goal > 0 ? (wf.actual / wf.goal) * 100 : -0,
          value: helper.formatNumber(wf.actual || 0, 0),
          real: wf.actual,
          inCircle: '', //wf.totalWellsPreApproveActual || 0,
          rank:
            helper.getUserGroup() === enums.userGroups.Showroom ||
            helper.getUserGroup() === enums.userGroups.Outlet ||
            helper.getUserGroup() === enums.userGroups.BobsSquad
              ? wpa || ''
              : '',
          goal: helper.formatNumber(wf.goal, 0),
          actual: helper.formatNumber(wf.actual, 0),
          splitLine: true,
          intpercentage: wf.intelligentRate,
          kpiType: helper.KPIType.twa,
        });
      }
    }

    data.forEach(
      (d) =>
        (d.coloring = helper.KPIColoringLogic(
          d.kpiType,
          d.goal,
          d.actual,
          d.percentage,
          d.intpercentage,
        )),
    );

    this.setState({
      isLoading: false,
      data: data,
    });
  }

  componentDidMount() {
    console.log('BDFGauge componentDidMount');
    let dashboardstr = localStorage.getItem('DashboardKPIs');

    if (dashboardstr !== '{}') this.getData();
    else {
      initdata.initDashboardKPI().then(() => this.getData());
    }

    this.getPerformanceMatrix();

    // Subscribe to scroll service
    this.unsubscribe = scrollService.subscribe((isCollapsed) => {
      this.setState({ fullStyle: !isCollapsed });
    });

    // Initial scroll position check
    const crContent = document.querySelector('.cr-app');
    if (crContent) {
      const scrollY = crContent.scrollTop;
      const fullStyle = scrollY < 28;
      this.setState({ fullStyle });
    }
  }

  componentWillUnmount() {
    // Cleanup scroll service subscription
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  componentDidUpdate(prevProps) {
    if (localStorage.getItem('GaugeUpdated') === 'true') {
      this.fetchData();
      localStorage.setItem('GaugeUpdated', false);
    }

    if (prevProps.isOutletView !== this.props.isOutletView) {
      console.log('isOutletView changed');

      this.fetchData();
      this.getPerformanceMatrix();
    }

    if (
      JSON.stringify(prevProps.userPreferences) !==
      JSON.stringify(this.props.userPreferences)
    ) {
      this.getData();
    }
  }

  async fetchData() {
    let view = localStorage.getItem('BobSquidManagerView');
    const isOutletView = this.props.isOutletView ? 'true' : 'false';

    // const response = await fetch(helper.getAPIHost() + "/api/Dashboard/getDashboardKPI?empID=" + helper.getEmplId(), helper.apiHeaders());
    const response = await fetch(
      helper.getAPIHost() +
        `/api/Dashboard/getDashboardKPI?isBobSquadMgr=${view}&outlet=${isOutletView}`,
      helper.apiHeaders(),
    );
    const result = await response.json();
    helper.catchServer(result);
    console.log('In initDashboardKPI');
    localStorage.setItem('DashboardKPIs', JSON.stringify(result.result));
    this.getData();
  }

  handlePopover(kpiType) {
    let currentData = this.state.data;

    currentData.forEach((d) => (d.showPopper = false)); //Reset all to false

    if (
      kpiType !== null &&
      kpiType !== undefined &&
      localStorage.getItem('BobSquidManagerView') === 'false'
    ) {
      let gaugeData = currentData.filter((data) => data.kpiType === kpiType)[0]; //Selected Gauge

      gaugeData.showPopper = !gaugeData.showPopper; //Toggle show/hide

      currentData.filter((data) => data.kpiType === kpiType)[0] = gaugeData;
    }

    this.setState({
      data: currentData,
    });
  }

  async getPerformanceMatrix() {
    let currentFiscal = JSON.parse(localStorage.getItem('FiscalCurrentMonth'));

    if (!currentFiscal) return;

    let userGroup = localStorage.getItem('UserGroupId');

    let role = '';
    if (this.props.isOutletView) {
      role = 'OUTLET';
    } else if (userGroup === '6' || userGroup === '5') {
      role = 'STORE';
    } else if (userGroup === '4') {
      role = 'OUTLET';
    } else {
      if (
        localStorage.getItem('BobSquidManagerView') === 'true' &&
        (userGroup === '2' || userGroup === '26')
      ) {
        //For national manager set as SQUAD
        role = 'SQUAD';
      } else {
        // For manager and others
        role = 'STORE';
      }
    }

    // Clear before refetch
    this.setState({
      performanceMatrix: {
        [helper.KPIType.ws]: [],
        [helper.KPIType.ds]: [],
        [helper.KPIType.nps]: [],
        [helper.KPIType.ga]: [],
        [helper.KPIType.pa]: [],
        [helper.KPIType.spch]: [],
        [helper.KPIType.twa]: [],
      },
    });

    let url =
      helper.getAPIHost() +
      `/api/Coaching/getPerformanceMatrix?fiscaldate=${currentFiscal.number}-${currentFiscal.fiscalYear}&role=${role}`;
    await fetch(url, { method: 'GET', ...helper.apiHeaders() })
      .then(async (response) => {
        let data = await response.json();
        if (data.status === 'OK') {
          let finalData = this.state.performanceMatrix;

          if (data.result?.length > 0) {
            data.result.forEach((item) => {
              // BCP-2397 - change all name to preferredName
              item.name = item.preferredName || item.name;

              finalData[helper.KPIType.ws].push({
                name: item.name,
                empId: item.salesAssociate,
                rank: item.writtenRank,
                value: item.writtenSales,
              });
              finalData[helper.KPIType.ds].push({
                name: item.name,
                empId: item.salesAssociate,
                rank: item.deliverRank,
                value: item.deliverSales,
              });
              finalData[helper.KPIType.nps].push({
                name: item.name,
                empId: item.salesAssociate,
                rank: item.npsRank,
                value: item.nps,
              });
              finalData[helper.KPIType.ga].push({
                name: item.name,
                empId: item.salesAssociate,
                rank: item.guardianAttachRank,
                value: item.guardianAttach,
              });
              finalData[helper.KPIType.pa].push({
                name: item.name,
                empId: item.salesAssociate,
                rank: item.protectorAttachRank,
                value: item.protectorAttachment,
              });
              finalData[helper.KPIType.spch].push({
                name: item.name,
                empId: item.salesAssociate,
                rank: item.salesPerCallHandledRank,
                value: item.salesPerCallHandle,
              });
              finalData[helper.KPIType.twa].push({
                name: item.name,
                empId: item.salesAssociate,
                rank: item.wellsPreAppRank,
                value: item.wellsPreApp,
              });
            });
          }

          //Sort
          finalData[helper.KPIType.ws].sort(
            this.sortDynamicPerformanceMatrix(true),
          );
          finalData[helper.KPIType.ds].sort(
            this.sortDynamicPerformanceMatrix(true),
          );
          finalData[helper.KPIType.nps].sort(
            this.sortDynamicPerformanceMatrix(true),
          );
          finalData[helper.KPIType.ga].sort(
            this.sortDynamicPerformanceMatrix(true),
          );
          finalData[helper.KPIType.pa].sort(
            this.sortDynamicPerformanceMatrix(true),
          );
          finalData[helper.KPIType.spch].sort(
            this.sortDynamicPerformanceMatrix(true),
          );
          finalData[helper.KPIType.twa].sort(
            this.sortDynamicPerformanceMatrix(true),
          );

          //Remove without rank
          finalData[helper.KPIType.ws] = finalData[helper.KPIType.ws].filter(
            (x) => x.rank,
          );
          finalData[helper.KPIType.ds] = finalData[helper.KPIType.ds].filter(
            (x) => x.rank,
          );
          finalData[helper.KPIType.nps] = finalData[helper.KPIType.nps].filter(
            (x) => x.rank,
          );
          finalData[helper.KPIType.ga] = finalData[helper.KPIType.ga].filter(
            (x) => x.rank,
          );
          finalData[helper.KPIType.pa] = finalData[helper.KPIType.pa].filter(
            (x) => x.rank,
          );
          finalData[helper.KPIType.spch] = finalData[
            helper.KPIType.spch
          ].filter((x) => x.rank);
          finalData[helper.KPIType.twa] = finalData[helper.KPIType.twa].filter(
            (x) => x.rank,
          );

          this.setState({ performanceMatrix: finalData });
        } else {
          ux.logError(
            'Dashboard KPI Gauge',
            'Get Data from API: getPerformanceMatrix',
            'Unable to get data from server!',
            url + ' ' + data.errorMsg,
          );
        }
      })
      .catch((error) => {
        ux.logError(
          'Dashboard KPI Gauge',
          'Get Data from API',
          'Init Data API:getPerformanceMatrix - ' + error,
          url,
        );
      });
  }

  sortDynamicPerformanceMatrix(ascending) {
    return function (a, b) {
      // equal items sort equally
      if (a.rank === b.rank) {
        return 0;
      }
      // nulls sort after anything else
      else if (a.rank === null) {
        return 1;
      } else if (b.rank === null) {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
        return a.rank < b.rank ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return a.rank < b.rank ? 1 : -1;
      }
    };
  }

  render() {
    const { showGaugeHeader } = this.props;

    if (this.state.isLoading) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          {showGaugeHeader && (
            <Table
              className={`iss-gauge-card-row ${
                this.state.fullStyle ? '' : 'mini'
              }`}
            >
              <tbody>
                <tr>
                  {this.state.data.map(
                    (
                      {
                        title,
                        percentage,
                        value,
                        real,
                        inCircle,
                        rank,
                        goal,
                        actual,
                        splitLine,
                        boldFirstLetter,
                        intpercentage,
                        coloring,
                        kpiType,
                        showPopper,
                      },
                      index,
                    ) => {
                      return (
                        <td
                          className="iss-gauge-card-item"
                          key={index}
                          title={`(${
                            intpercentage > percentage && intpercentage > 0
                              ? helper.formatNumber(intpercentage, 0)
                              : helper.formatNumber(percentage, 0)
                          }%) ${actual} of goal ${goal}`}
                        >
                          <div>
                            <div className="text">
                              <div className="title">
                                {!splitLine && title}
                                {splitLine &&
                                  title.split(' ').map(function (item, index) {
                                    // to add <br>
                                    return (
                                      <span key={index}>
                                        {boldFirstLetter && (
                                          <span className="subbold">
                                            <b>{item.substr(0, 1)}</b>
                                            {item.substr(1).replace('|', ' ')}
                                          </span>
                                        )}
                                        {!boldFirstLetter &&
                                          item.replace('|', ' ')}

                                        <br />
                                      </span>
                                    );
                                  })}
                              </div>
                              {value !== null &&
                                value !== undefined &&
                                value !== '' && (
                                  <div className="value">
                                    {this.state.fullStyle
                                      ? value
                                      : real >= 1000000
                                      ? helper.formatNumber(real, 0)
                                      : value}
                                  </div>
                                )}
                            </div>

                            <div
                              className="float"
                              onClick={() => this.handlePopover(kpiType)}
                              onBlur={() => this.handlePopover(null)}
                            >
                              <div className="precent">{inCircle}</div>
                              {rank !== null &&
                                rank !== undefined &&
                                rank !== '' && (
                                  <div className="rank">
                                    {this.state.fullStyle ? 'Rank' : ''}
                                    <br />#{rank}
                                  </div>
                                )}

                              {showPopper && (
                                <ClickAwayListener
                                  onClickAway={() => this.handlePopover(null)}
                                >
                                  <div
                                    className={'rank-popper'}
                                    style={{
                                      left: `${
                                        kpiType === helper.KPIType.twa ||
                                        kpiType === helper.KPIType.spch
                                          ? '-180px'
                                          : '-15px'
                                      }`,
                                    }}
                                  >
                                    <Table>
                                      <thead>
                                        <tr>
                                          <th>Rank</th>
                                          <th>Name</th>
                                          <th>{title.replaceAll('|', ' ')}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.performanceMatrix[kpiType]
                                          .length > 0 &&
                                          this.state.performanceMatrix[
                                            kpiType
                                          ].map((data) => {
                                            return (
                                              <tr
                                                className={`${
                                                  localStorage.getItem(
                                                    'EmplId',
                                                  ) === data.empId
                                                    ? 'own'
                                                    : ''
                                                }`}
                                                key={data.name}
                                              >
                                                <td>
                                                  {data.rank
                                                    ? `#${data.rank}`
                                                    : ''}
                                                </td>
                                                <td>{data.name}</td>
                                                <td>
                                                  {(kpiType ===
                                                    helper.KPIType.ws ||
                                                    kpiType ===
                                                      helper.KPIType.ds ||
                                                    kpiType ===
                                                      helper.KPIType.spch) &&
                                                    helper.formatNumber(
                                                      data.value,
                                                      2,
                                                      '$',
                                                    )}

                                                  {(kpiType ===
                                                    helper.KPIType.nps ||
                                                    kpiType ===
                                                      helper.KPIType.twa) &&
                                                    helper.formatNumber(
                                                      data.value,
                                                      0,
                                                    )}

                                                  {(kpiType ===
                                                    helper.KPIType.ga ||
                                                    kpiType ===
                                                      helper.KPIType.pa) &&
                                                    helper.formatNumber(
                                                      data.value,
                                                      1,
                                                      '',
                                                      '%',
                                                    )}
                                                </td>
                                              </tr>
                                            );
                                          })}

                                        {this.state.performanceMatrix[kpiType]
                                          .length === 0 && (
                                          <tr>
                                            <td
                                              colSpan={3}
                                              style={{ paddingTop: '10px' }}
                                            >
                                              No data available
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </ClickAwayListener>
                              )}
                            </div>

                            {/* {console.log(intpercentage)} */}
                            <div
                              className="circle"
                              onClick={() => this.handlePopover(kpiType)}
                              onBlur={() => this.handlePopover(null)}
                              style={{
                                cursor: `${
                                  localStorage.getItem(
                                    'BobSquidManagerView',
                                  ) === 'true'
                                    ? 'auto'
                                    : 'pointer'
                                }`,
                              }}
                            >
                              {/* {(percentage > 0) && ( */}
                              <Circle
                                animate={true} // Boolean: Animated/Static progress
                                animationDuration="2s" //String: Length of animation
                                size={this.state.fullStyle ? 60 : 30} // Number: Defines the size of the circle.
                                lineWidth={40} // Number: Defines the thickness of the circle's stroke.
                                progress={
                                  percentage > 100 ? 100 : percentage || 0
                                } // Number: Update to change the progress and percentage.
                                // progressColor={`${
                                //   intpercentage > percentage && intpercentage > 0
                                //     ? helper.getConditionColor(intpercentage)
                                //     : helper.getConditionColor(percentage)
                                // }`} // String: Color of "progress" portion of circle.
                                progressColor={coloring.colorHex}
                                bgColor="#e0e0e0" // String: Color of "empty" portion of circle.
                                textColor="black" // String: Color of percentage text color.
                                roundedStroke={true} // Boolean: Rounded/Flat line ends
                                showPercentage={false} // Boolean: Show/hide percentage.
                                showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
                              />
                              {/* )} */}
                            </div>
                          </div>
                        </td>
                      );
                    },
                  )}
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      );
    }
  }
}

BDFGauge.propTypes = {
  pageTitle: PropTypes.string,
};

BDFGauge.defaultProps = {
  pageTitle: '',
};

export default withRouter(BDFGauge);
